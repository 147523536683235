import React, { useState } from "react";
import { Body } from "../login/login-style";
import { LoginDiv, LoginInput, Text } from "./alterar-senha-style";
import axios from "axios";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import { toastProps } from "../../utils/toastProps";
import logo from "../../assets/logoFundoTransparente.png";
import api from "../../services/api";
import eye from "../../assets/images.png";
import closedeye from "../../assets/hide.png.webp";
import { useHistory } from "react-router";
import rotulosLogo from "../../assets/4 Logo.fundo.transparente.png";

function AlterarSenha(props) {
  const [newpass, setNewpass] = useState("");
  const [renewpass, setRenewpass] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState("password");
  const [showConfirmedPassword, setShowConfirmedPassword] =
    useState("password");
  const history = useHistory();

  //const logo =
  //"https://storage-fluxo.nyc3.digitaloceanspaces.com/appcosmeticos/RotuloNude.png";
  const url = "http://rotulonude.com/auth/users/reset_password_confirm/";
  async function AlterPass(e) {
    if (newpass !== renewpass) {
      e.preventDefault();
      setMessage("As duas senhas estão diferentes!");
    } else if (newpass.length < 8) {
      e.preventDefault();
      setMessage("A senha deve ter no mímino 8 caracteres!");
    } else if (/^\d+$/.test(newpass)) {
      e.preventDefault();
      setMessage("A senha não pode ser inteiramente numérica!");
    } else {
      setIsLoading(true);
      const obj = {
        uid: props.match.params.uid,
        token: props.match.params.token,
        new_password: newpass,
        re_new_password: renewpass,
      };

      try {
        e.preventDefault();
        await api.post("/auth/users/reset_password_confirm/", obj);
        toast.success("Senha alterada com sucesso!", toastProps);
        history.push("/");
      } catch (error) {
        toast.error("Erro ao alterar senha!", toastProps);
      }
      setIsLoading(false);
    }
  }

  return (
    <Body>
      <Toast />
      <Text>
        <img src={rotulosLogo} alt="RotuloNude" style={{ width: "30vw" }} />
      </Text>
      <form>
        <Text>
          <label>Alterar minha senha</label>
        </Text>
        <LoginDiv>
          <LoginInput
            type={showPassword}
            placeholder="Insira sua nova senha"
            required="true"
            id="password"
            onChange={(event) => setNewpass(event.target.value)}
          />
          {showPassword === "password" ? (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowPassword("text")}
            >
              <img style={{ width: "30px", height: "30px" }} src={eye}></img>
            </div>
          ) : (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowPassword("password")}
            >
              <img
                style={{ width: "30px", height: "30px" }}
                src={closedeye}
              ></img>
            </div>
          )}
        </LoginDiv>
        <LoginDiv>
          <LoginInput
            type={showConfirmedPassword}
            placeholder="Repita sua nova senha"
            required="true"
            id="password"
            onChange={(event) => setRenewpass(event.target.value)}
          />
          {showConfirmedPassword === "password" ? (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowConfirmedPassword("text")}
            >
              <img style={{ width: "30px", height: "30px" }} src={eye}></img>
            </div>
          ) : (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowConfirmedPassword("password")}
            >
              <img
                style={{ width: "30px", height: "30px" }}
                src={closedeye}
              ></img>
            </div>
          )}
        </LoginDiv>
        <Text>
          <p>{message}</p>
        </Text>
        <button type="submit" onClick={(e) => AlterPass(e)}>
          {isLoading ? (
            <ReactLoading type="spin" color="#fff" height={20} width={20} />
          ) : (
            "Enviar"
          )}
        </button>
      </form>
    </Body>
  );
}
export default AlterarSenha;
