import styled from "styled-components";

export const TextAreaModal = styled.textarea`
  width: 90%;
  background-color: transparent;
  border: none;
  height: 85%;
`;

export const TextInputModal = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  height: 85%;
`;

export const TextDivModal = styled.div`
  padding: 0px 10px;
  height: ${(props) => (props.height ? props.height : null)}px;
  width: 90%;
  background-color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  transition: 0.25s;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
