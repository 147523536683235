import React from "react";
import { Body, LinkTo, LoginDiv, LoginInput } from "./login-style";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { isAuthenticated, onSignIn } from "../../services/auth";
import logo from "../../assets/logoFundoTransparente.png";
import api from "../../services/api";
import eye from "../../assets/images.png";
import closedeye from "../../assets/hide.png.webp";
import rotulosLogo from "../../assets/4 Logo.fundo.transparente.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasToken, setToken] = useState(false);
  const [mensagemErro, setMensagemErro] = useState("");
  const [showPassword, setShowPassword] = useState("password");

  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) history.push("/home");
    console.log(isAuthenticated());
  }, [history]);

  async function PostAxios(e) {
    setIsLoading(true);

    const postData = { email: email, password: password };

    try {
      e.preventDefault();
      const response = await api.post("/api/loginadmin/", postData);

      onSignIn(response.data.token);
      setToken(true);
      history.push("/home");
    } catch (error) {
      setMensagemErro(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Body>
      <img src={rotulosLogo} alt="RotuloNude" style={{ width: "30vw" }} />
      <form>
        <input
          type="email"
          placeholder="Insira seu email"
          id="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <LoginDiv>
          <LoginInput
            type={showPassword}
            placeholder="Insira sua senha"
            onChange={(event) => setPassword(event.target.value)}
          />
          {showPassword === "password" ? (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowPassword("text")}
            >
              <img style={{ width: "30px", height: "30px" }} src={eye}></img>
            </div>
          ) : (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowPassword("password")}
            >
              <img
                style={{ width: "30px", height: "30px" }}
                src={closedeye}
              ></img>
            </div>
          )}
        </LoginDiv>
        <button
          type="submit"
          onClick={(e) => {
            PostAxios(e);
          }}
        >
          {isLoading ? "Carregando" : "Entrar"}
        </button>
      </form>
      <h1>{mensagemErro}</h1>
      <LinkTo to="/EsqueciMinhaSenha">Esqueci minha senha</LinkTo>
    </Body>
  );
}
export default Login;
