import React, { useState, useEffect } from "react";
import "../../global-styles.css";
import api from "../../services/api";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Plus from "../../assets/plus.svg";
import { Adicionar, TextoClicavel } from "./adicionar-style";
import Modal from "../modal/modal.js";
import axios from "axios";
import Busca from "../busca/busca";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import ErrorMessage from "../ErrorMessage";
import { TextAreaModal, TextDivModal } from "../TextField/styles";
import Select from "react-select";
import { Colors } from "../../utils/theme/constants";
import CloseAdvice from "../CloseAdvice/index.js";

//loading

function AdicionarProduto({ getProducts }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalcloseAdviceIsOpen, setModalcloseAdviceIsOpen] = useState(false);
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [certificados, setCertificados] = useState([]);
  const [categoriasFinais, setCategoriasFinais] = useState([]);
  const [opcoesCertificados, setOpcoesCertificados] = useState([]);
  const [marca, setMarca] = useState("");
  const [ingredientes, setIngredientes] = useState([]);
  const [score, setScore] = useState(null);
  const [bigestScore, setBigestScore] = useState(null);
  const [volume, setVolume] = useState("");
  const [upc, setUpc] = useState("");
  const [imagem, setImagem] = useState([]);
  const [nomeImagem, setNomeImagem] = useState("");
  const [novaCategoria, setNovaCategoria] = useState("");
  const [adicionarCategoriaIsOpen, setAdicionarCategoriaIsOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [mensagemErro, setMensagemErro] = useState("");
  const [isFetchingOpcoesCategoria, setIsFetchingOpcoesCategoria] =
    useState(true);
  const [opcoesCategoria, setOpcoesCategoria] = useState([]);
  const [opcoesSubcategoria, setOpcoesSubcategoria] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);

  const [nameErro, setNameErro] = useState(false);
  const [categoriesErro, setCategoriesErro] = useState(false);
  const [marcaErro, setMarcaErro] = useState(false);
  const [upcErro, setUpcErro] = useState(false);
  const [scoreErro, setScoreErro] = useState(false);

  const checkErrors = () => {
    let hasErrors = false;

    if (marca === "") {
      setMarcaErro(true);
      hasErrors = true;
    } else {
      setMarcaErro(false);
    }

    if (nome === "") {
      setNameErro(true);
      hasErrors = true;
    } else {
      setNameErro(false);
    }

    if (categoriasFinais[0] === undefined) {
      setCategoriesErro(true);
      hasErrors = true;
    } else {
      setCategoriesErro(false);
    }

    if (upc === "") {
      setUpcErro(true);
      hasErrors = true;
    } else {
      setUpcErro(false);
    }

    if (scoreErro || score === false) hasErrors = true;

    return hasErrors;
  };

  const getCertificados = async () => {
    try {
      const res = await api.get("/api/selos/");
      setOpcoesCertificados(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCertificados();
    const fetchCategories = async () => {
      setIsFetchingOpcoesCategoria(true);

      try {
        const { data: fetchedCategories } = await api.get(
          "/api/categorias/all/"
        );
        setOpcoesCategoria(fetchedCategories);
        setOpcoesSubcategoria(fetchedCategories);
      } catch (error) {
        toast.error("Erro ao carregar categorias");
      } finally {
        setIsFetchingOpcoesCategoria(false);
      }
    };

    if (modalIsOpen) {
      fetchCategories();
    }
  }, [modalIsOpen]);
  let urlImagem = "";

  const adicionarProduto = async (e) => {
    e.preventDefault();

    if (checkErrors()) return;

    setLoading(true);
    let certificadosId = [];

    certificados.forEach((certificado) => {
      certificadosId.push(certificado.id);
    });

    let ingredientesId = [];
    ingredientesId = ingredientes.map((ingrediente) => {
      return ingrediente.id;
    });

    const body = {
      nome: nome,
      categorias: categoriasFinais,
      marca: marca,
      volume: volume,
      upc: upc,
      image_url: nomeImagem,
      ingredientes: ingredientesId,
      selos: certificadosId,
    };

    console.log(body);

    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Produto",
        operation: "+",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      await api.post("/api/produtos/", body);
      setMessageIsOpen(true);
    } catch (error) {
      console.log(error);
      setMensagemErro(error?.response?.data?.upc);
    }
    setLoading(false);
    getProducts();
  };
  const handleClose = () => {
    setMessageIsOpen(false);
    getProducts();
    setModalIsOpen(false);
  };
  const adicionarCategoria = async () => {
    const body = {
      nome: novaCategoria,
    };
    api.post("/api/categorias/", body);
  };

  const onFileUpload = async () => {
    if (!imagem.type) {
      return;
    }

    const typeNovo = imagem.type.substring([6]);
    setLoadingUpload(true);
    await api
      .get("/api/file/upload/" + typeNovo + "/")
      .then(async (res) => {
        urlImagem = res.data.media_url;
        setNomeImagem(res.data.file_name);
        let foo = imagem.slice(0, imagem.size, imagem.type);
        let bar = new File([foo], res.data.file_name, {
          type: imagem.type,
        });
        const formData = new FormData();
        formData.append("file_name", res.data.file_name);
        formData.append("file", imagem);
        console.log("res.data.file_name", res.data.file_name);
        console.log("imagem", imagem);

        await api
          .post("/api/file/upload/" + typeNovo + "/", formData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        // const data = {
        //   file_name: res.data.file_name,
        // };

        // api.post("/api/file/upload/" + typeNovo + "/", bar, {
        //   headers: {
        //     "x-amz-acl": "public-read",
        //     "Content-Type": bar.type,
        //     "Content-Disposition": "attachment",
        //   },
        // });
      })
      .catch((err) => console.log(err));

    setLoadingUpload(false);
  };

  useEffect(() => {
    if (score > 10 || score < 0) {
      setScoreErro(true);
    } else {
      setScoreErro(false);
    }
  }, [score]);

  const eventhandlerIngrediente = (data) => {
    let ingredientesId = [];
    console.log(data);
    let bigestScore = 0;
    for (let item of data) {
      ingredientesId.push(item.id);
      if (item.score !== null) {
        if (parseInt(item.score, 10) > bigestScore) {
          bigestScore = item.score;
        }
      }
    }
    ingredientesId = data;
    setIngredientes(ingredientesId);
    setBigestScore(bigestScore);
  };

  useEffect(() => {
    let categoriasId = [];
    categoriasId[0] = categoria?.id;

    subcategorias.forEach((subcategoria) => {
      categoriasId.push(subcategoria.id);
    });

    setCategoriasFinais(categoriasId);
  }, [categoria, subcategorias]);

  return (
    <Adicionar>
      <button type="button" onClick={() => setModalIsOpen(true)}>
        <p style={{ color: Colors.White }}>Adicionar Produto</p>
        {/* <img src={Plus} alt="Mais" /> */}
      </button>
      {modalIsOpen ? (
        <Modal
          style={{ backgroundColor: "#efefef" }}
          container="containerAdicionar"
          onClose={() => setModalcloseAdviceIsOpen(true)}
        >
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            Adicionar Produto
          </p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="nome">
              Nome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                type="text"
                id="nome"
                value={nome}
                onChange={(event) => setNome(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage isVisible={nameErro} message="Preencha o Nome" />
            <label htmlFor="categorias">
              Categoria<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300, marginTop: "10px" }}
                options={opcoesCategoria}
                loading={isFetchingOpcoesCategoria}
                loadingText={"Carregando Categorias..."}
                autoHighlight
                getOptionLabel={(option) => option.nome}
                onChange={(selected, values) => setCategoria(values)}
                renderOption={(option) => <>{option.nome}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            <ErrorMessage
              isVisible={categoriesErro}
              message="Preencha a categoria"
            />
            <label htmlFor="categorias">Subcategorias</label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300 }}
                options={opcoesSubcategoria}
                loading={isFetchingOpcoesCategoria}
                loadingText={"Carregando Subcategorias..."}
                onChange={(selected, values) => setSubcategorias(values)}
                autoHighlight
                multiple
                getOptionLabel={(option) => option?.nome}
                renderOption={(option) => <>{option?.nome}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            <TextoClicavel>
              <button
                type="button"
                onClick={() => setAdicionarCategoriaIsOpen(true)}
              >
                Nova Categoria
              </button>
            </TextoClicavel>
            <label htmlFor="ingredientes">Ingredientes</label>
            <TextDivModal>
              <Busca
                url="/api/ingredientes-sw/"
                onChange={eventhandlerIngrediente}
                addAction
                listOld={ingredientes}
              />
            </TextDivModal>
            <p>
              Score mais alto: <b>{bigestScore}</b>
            </p>
            <label htmlFor="certificados">Selos</label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300, marginTop: "10px" }}
                options={opcoesCertificados}
                multiple
                autoHighlight
                getOptionLabel={(option) => option?.nome}
                onChange={(selected, values) => setCertificados(values)}
                renderOption={(option) => <>{option?.nome}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            <label htmlFor="marca">
              Marca<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                type="text"
                id="marca"
                value={marca}
                onChange={(event) => setMarca(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage isVisible={marcaErro} message="Preencha a marca" />
            <label htmlFor="Volume">Volume</label>
            <TextDivModal height="40">
              <TextAreaModal
                type="text"
                id="Volume"
                value={volume}
                onChange={(event) => setVolume(event.target.value)}
              />
            </TextDivModal>
            {/* <label id="score">Score</label>
            <TextDivModal height="40">
              <TextAreaModal
                type="number"
                id="score"
                value={score}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
                onChange={(event) => setScore(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage
              isVisible={scoreErro}
              message="O score deve ser de 0 a 10"
            /> */}
            <label id="upc">
              Código de Barras<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                type="text"
                id="upc"
                value={upc}
                onChange={(event) => setUpc(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage
              isVisible={upcErro}
              message="Preencha o código de barras"
            />
            <label id="imagem">Imagem</label>
            <input
              id="imagem"
              type="file"
              onChange={(event) => setImagem(event.target.files[0])}
              accept="image/*"
            />
            {loadingUpload && (
              <ReactLoading
                type="spokes"
                color="#E6AC86"
                height={20}
                width={20}
              />
            )}
            <TextoClicavel>
              <button type="button" onClick={() => onFileUpload()}>
                Confimar Imagem
              </button>
            </TextoClicavel>
            <button
              type="submit"
              onClick={(e) => adicionarProduto(e)}
              className="enviar"
              style={{ cursor: "pointer" }}
            >
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              ) : (
                "Adicionar"
              )}
            </button>
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}

      {modalcloseAdviceIsOpen ? (
        <CloseAdvice
          setModalcloseAdviceIsOpen={setModalcloseAdviceIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      ) : null}

      {messageIsOpen ? (
        <Modal container="containerAviso" onClose={handleClose}>
          <p>Produto Adicionado com Sucesso!</p>
        </Modal>
      ) : null}

      {adicionarCategoriaIsOpen ? (
        <Modal
          container="containerAviso"
          onClose={() => setAdicionarCategoriaIsOpen(false)}
        >
          <p>Adicione uma nova categoria:</p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label id="nova">Nova Categoria</label>
            <TextField
              style={{ width: 300, marginTop: "10px" }}
              id="nova"
              type="text"
              value={novaCategoria}
              onChange={(event) => setNovaCategoria(event.target.value)}
            />
            <input
              type="button"
              value="Adicionar"
              onClick={(e) => adicionarCategoria(e)}
              className="submitForm"
            />
          </form>
        </Modal>
      ) : null}
    </Adicionar>
  );
}
export default AdicionarProduto;
