import React, { useState, useEffect } from "react";
import { ListCategory, ListElement } from "./box-lista-style.js";
import api from "../../services/api.js";
import axios from "axios";
import { toast } from "react-toastify";
import Cancelar from "../../assets/cancelar.svg";
import edit from "../../assets/editTable.svg";
import CreatedeIcon from "@material-ui/icons/Create";
import Modal from "../modal/modal.js";
import ReactLoading from "react-loading";
import { Link, TextField } from "@material-ui/core";
import { TextAreaModal, TextDivModal } from "../TextField/styles.js";

import "../../global-styles.css";
import { toastProps } from "../../utils/toastProps.js";
import { ContainerHeaderTable } from "../HeaderTable/styles.js";
import { IconImage, TextTable } from "./styles.js";
import { LinkItem } from "../header/header-style.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const getUrl = (id) => {
  return `/api/categorias/${id}/produtos/extended/`;
};

function BoxCategorias({ nome, imagem, id, GetCategorias }) {
  const [deletarCategoriaIsOpen, setDeletarCategoriaIsOpen] = useState(false);
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false);
  const [changeImageIn, setChangeImageIn] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [produtosNovos, setProdutosNovos] = useState([]);
  const [novaImagem, setNovaImagem] = useState([]);
  const [novaImagemName, setNovaImagemName] = useState("");
  const [novoNome, setNovoNome] = useState(nome);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [data, setData] = useState();
  const history = useHistory();
  const deletarCategoria = async (event) => {
    event.preventDefault();
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Categoria",
        operation: "-",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      await api.delete("api/categorias/" + id + "/");
      toast.success("Categoria removida com sucesso!", toastProps);
    } catch (error) {
      toast.error("Erro ao editar categoria", toastProps);
    }
    setDeletarCategoriaIsOpen(false);
    GetCategorias();
  };

  const editarCategoria = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Categoria",
        operation: "~",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      const body =
        novaImagemName === ""
          ? { nome: novoNome }
          : {
              nome: novoNome,
              image_url: novaImagemName,
            };
      await api.patch(`/api/categorias/${id}/`, body);
      toast.success("Categoria alterada com sucesso!", toastProps);
    } catch {
      toast.error("Erro ao editar categoria", toastProps);
    }

    setLoading(false);
    setModalEditarIsOpen(false);
    GetCategorias();
  };

  const putImagem = () => {
    if (!novaImagem.type) {
      return;
    }
    const typeNovo = novaImagem.type.substring([6]);
    console.log(typeNovo);
    setNovaImagemName("");
    setLoadingUpload(true);
    api
      .get("/api/file/upload/" + typeNovo + "/")
      .then(async (res) => {
        // let bar = new File([foo], res.data.file_name, {
        //   type: imagem.type,
        // });
        const formData = new FormData();
        formData.append("file_name", res.data.file_name);
        formData.append("file", novaImagem);
        console.log("res.data.file_name", res.data.file_name);
        api
          .post("/api/file/upload/" + typeNovo + "/", formData)
          .then((response) => {
            api
              .patch(`/api/categorias/${id}/`, {
                image_url: res.data.file_name,
              })
              .then((response) => {
                console.log();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })

      .catch((err) => console.error(err))
      .finally(() => setLoadingUpload(false));
  };

  const getInfoCategorias = async () => {
    setProdutosNovos([]);
    try {
      const response = await api.get(getUrl(id));
      setProdutos(response.data.results);
    } catch (error) {
      console.log("erro");
    }
  };

  useEffect(() => {
    getInfoCategorias();
  }, []);

  function ListaProdutos() {
    return (
      <ul>
        {produtos?.map((produto, index) => {
          return (
            <ol key={index}>
              {produto.nome} <b>{produto.score}</b>
            </ol>
          );
        })}
      </ul>
    );
  }

  function ListaProdutosNovos() {
    return (
      <ul>
        {produtosNovos?.map((produto, index) => {
          return (
            <ol key={index}>
              {produto.nome} <b>{produto.score}</b>
            </ol>
          );
        })}
      </ul>
    );
  }

  function handleChange() {
    console.log(id);
    history.push({ pathname: "/Produtos", state: getUrl(id) });
  }

  return (
    <>
      <ListElement>
        <ContainerHeaderTable
          width="80"
          minWidth="100"
          justify="start"
          marginLeft={30}
          marginRight={10}
        >
          <TextTable onClick={() => setModalEditarIsOpen(true)}>
            <b>{nome}</b>
          </TextTable>
        </ContainerHeaderTable>

        <ContainerHeaderTable
          // width="15"
          minWidth="90"
          justify="space-evenly"
          marginLeft={0}
          marginRight={30}
        >
          <IconImage
            width={20}
            height={20}
            src={edit}
            onClick={() => setModalEditarIsOpen(true)}
          ></IconImage>
          <IconImage
            width={20}
            height={20}
            src={Cancelar}
            onClick={(e) => setDeletarCategoriaIsOpen(true)}
          ></IconImage>
        </ContainerHeaderTable>

        {modalEditarIsOpen && (
          <Modal
            container="containerEditarCategoria"
            onClose={() => setModalEditarIsOpen(false)}
          >
            <div id="divEditarCategoria">
              <label
                htmlFor="nome_categoria"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                Editar Categoria
              </label>
              <TextDivModal height="40">
                <TextAreaModal
                  value={novoNome}
                  onChange={(e) => setNovoNome(e.target.value)}
                  id="nome_categoria"
                />
              </TextDivModal>
              {imagem && imagem !== "" ? (
                <img src={imagem} width="200" height="200" alt={nome} />
              ) : null}
              <div style={{ height: "10px" }}>
                <button
                  style={{
                    backgroundColor: "#E6AC86",
                    border: "none",
                    borderRadius: "10px",
                    margin: "0 auto 20px auto !important",
                    padding: "5px",
                    paddingBottom: "10px",
                    fontSize: "13px",
                    color: "white",
                    width: 100 % "!important",
                    height: "25px",
                  }}
                  onClick={() => setChangeImageIn(!changeImageIn)}
                >
                  Alterar Imagem
                </button>
              </div>
              {changeImageIn && (
                <div id="divAlterarImagem">
                  <input
                    type="file"
                    onChange={(event) => setNovaImagem(event.target.files[0])}
                    accept="image/*"
                  />
                  <button onClick={() => putImagem()}>Confirmar imagem</button>
                  {loadingUpload && (
                    <ReactLoading
                      type="spokes"
                      color="#E6AC86"
                      height={20}
                      width={20}
                    />
                  )}
                </div>
              )}

              <label htmlFor="produtos_da_categoria">
                {" "}
                Produtos contidos na Categoria
              </label>
              {/*<div onClick={() => getInfoCategorias()}>
                {produtosNovos.length > 0
                  ? ListaProdutosNovos()
                  : ListaProdutos()}
              </div>
              */}

              <button
                style={{
                  backgroundColor: "#E6AC86",
                  border: "none",
                  borderRadius: "10px",
                  margin: "0 auto 20px auto !important",
                  padding: "5px",
                  paddingBottom: "10px",
                  fontSize: "13px",
                  color: "white",
                  width: 100 % "!important",
                  height: "25px",
                }}
                onClick={() => handleChange()}
              >
                Ver Produtos
              </button>

              <button
                //value="Confirmar"
                style={{ cursor: "pointer" }}
                onClick={(event) => editarCategoria(event)}
                className="enviar"
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                ) : (
                  "Confirmar"
                )}
              </button>
            </div>
          </Modal>
        )}

        {deletarCategoriaIsOpen ? (
          <Modal
            container="containerAvisoDeletar"
            onClose={() => setDeletarCategoriaIsOpen(false)}
          >
            <h2 id="textoDeletar">
              Deseja mesmo deletar este Categoria de forma definitiva?
            </h2>
            <div id="botoesDeletar">
              <button id="deletar" onClick={(e) => deletarCategoria(e, id)}>
                SIM
              </button>
              <button
                id="deletar"
                onClick={() => setDeletarCategoriaIsOpen(false)}
              >
                NÃO
              </button>
            </div>
          </Modal>
        ) : null}
      </ListElement>
    </>
  );
}
export default BoxCategorias;
