import React from "react";
import Header from "../../../components/header/header";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import Toast from "../../../components/Toast";
import { Tela, InlineStyle, Box } from "../ingredientes/ingredientes-style";
import AdicionarTrending from "../../../components/modalAdicionar/adicionarTrending";
import BoxTrending from "../../../components/box-listas/box-trending";
import NamePages from "../../../components/NamePages";
import {
  ClickableArea,
  ContainerHeaderTable,
  HeaderTable,
  TextHeaderTable,
} from "../../../components/HeaderTable/styles";

import styled from "styled-components";
import ButtonOrderBy from "../../../components/buttonOrderBy";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";
import { LinkItem } from "../../../components/header/header-style";
import { Adicionar } from "../../../components/modalAdicionar/adicionar-style";

function Trending() {
  const [trending, setTrending] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [orderBySelect, setOrderBySelect] = useState("nome");
  const [typeOrder, setTypeOrder] = useState("ascendent");

  const onPressButtonOrder = (order) => {
    console.log("order", order);
    console.log("orderBySelect", orderBySelect);
    if (order === orderBySelect) {
      typeOrder === "ascendent"
        ? setTypeOrder("descendent")
        : setTypeOrder("ascendent");
    } else {
      setTypeOrder("ascendent");
      setOrderBySelect(order);
    }
  };

  const getTrending = () => {
    console.log(
      "requisicao",
      `/api/trending?ordering=${
        typeOrder === "ascendent" ? "" : "-"
      }${orderBySelect}`
    );
    api
      .get(
        `/api/trending?ordering=${
          typeOrder === "ascendent" ? "" : "-"
        }${orderBySelect}`
      )
      .then(function (response) {
        if (response.data.length === 0) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
        setTrending(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTrending();
  }, [orderBySelect, typeOrder]);

  return (
    <>
      <Toast />
      <div>
        <Header />
        <Tela>
          <NamePages BackgroundColor={"#E6AC86"} NamePage={"Trendings"} />
          <InlineStyleadapted>
            <AdicionarTrending getTrending={getTrending} />
          </InlineStyleadapted>
          <RowButtonsOrderby>
            <ButtonOrderBy
              onPress={() => {
                onPressButtonOrder("nome");
              }}
              title={
                typeOrder === "ascendent"
                  ? "clique para ordenar de forma descendente"
                  : "clique para ordenar de forma ascendete"
              }
              isSelected={orderBySelect === "nome" ? true : false}
              type="string"
              ascendent={typeOrder}
              text="Nome"
            />

            <LinkItem to="/Trending/Highlights">
              <Adicionar>
                <button style={{ color: "white" }}>Gerenciar Destaques</button>
              </Adicionar>
            </LinkItem>
          </RowButtonsOrderby>
          <HeaderTable backgroundColor="#A5553C">
            <ContainerHeaderTable
              width="80"
              justify="start"
              marginLeft={30}
              marginRight={10}
            >
              {/* <ClickableArea
                title={
                  orderBySelect === "nome"
                    ? "Retirar Ordenação"
                    : "Ordenar por Nome"
                }
                onClick={() => {
                  orderBy("nome");
                }}
                isSelect={orderBySelect === "nome" ? true : false}
              > */}
              <TextHeaderTable>Nome</TextHeaderTable>
              {/* </ClickableArea> */}
            </ContainerHeaderTable>
            <ContainerHeaderTable
              minWidth="90"
              justify="center"
              marginLeft={0}
              marginRight={30}
            >
              {/* <ClickableArea> */}
              <TextHeaderTable>Opções</TextHeaderTable>
              {/* </ClickableArea> */}
            </ContainerHeaderTable>
          </HeaderTable>
          {isEmpty ? (
            <h1>Ainda não há dados nesta página</h1>
          ) : (
            <Box>
              {trending.map((destaque) => (
                <div key={destaque.id}>
                  <BoxTrending
                    id={destaque.id}
                    nome={destaque.nome}
                    imagem={destaque.image_url}
                    texto={destaque.texto}
                    url_insta={destaque.url_insta}
                    getTrending={getTrending}
                  />
                </div>
              ))}
            </Box>
          )}
        </Tela>
      </div>
    </>
  );
}

const InlineStyleadapted = styled(InlineStyle)`
  flex-direction: row-reverse;
`;

export default Trending;
